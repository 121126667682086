import { ChurnZeroEventName } from 'api/Api.generated';
import React from 'react';
import { matchOrgKey } from 'routing';
import { routingStore } from 'stores';
import styled, { css } from 'styled-components';
import { Body, Container, Link, P } from 'styles';
import { getOrgDetails } from 'styles/theme';

interface PublicLayoutComponent {
    footer?: boolean;
    wide?: boolean;
    sendEvent: (eventName: ChurnZeroEventName, token: string | null) => void;
}

const Footer = styled.div<{ wide: boolean }>`
    ${({ wide }) =>
        wide &&
        css`
            @media (min-width: 768px) {
                padding: 0 2rem;
            }
        `}
`;

const FooterLink = styled(Link)`
    font-weight: 400;
`;

export const PublicLayout: React.FC<PublicLayoutComponent> = ({
    children,
    footer = true,
    wide = false,
    sendEvent,
}) => {
    const onClick = () => {
        sendEvent(ChurnZeroEventName.AlfiepayCallSupport, null);
    };

    const orgKey = matchOrgKey(routingStore.location);
    const { helpPhone } = getOrgDetails(orgKey);

    return (
        <Body>
            <Container wide={wide}>
                {children}
                {footer && helpPhone && (
                    <Footer wide={wide}>
                        <P>
                            Call{' '}
                            <FooterLink onClick={onClick} href={`tel:${helpPhone}`}>
                                {helpPhone}
                            </FooterLink>{' '}
                            for more information and assistance.
                        </P>
                    </Footer>
                )}
            </Container>
        </Body>
    );
};
